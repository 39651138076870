.container {
  display: flex;
  align-items: center;
}

.mainLayout {
  justify-content: center;
  flex-direction: column;
}

.mainTitle {
  color: #7015ff;
  font-weight: 700;
  text-align: center;
}

.title {
  flex-direction: row;
  margin-bottom: 45px;
  font-weight: 700;
  font-size: 48px;
  text-align: center;
  z-index: 2;
}

.positionMain {
  position: relative;
}

.positionExtra {
  position: absolute;
}

.decor1 {
  right: -300px;
  top: -25px;
}

.decor2 {
  left: -500px;
}

.decor3 {
  right: -75px;
  bottom: 250px;
}

.txtGrateful {
  text-align: center;
  word-wrap: break-word;
}

.locationGatefulVi {
  width: 225px;
  left: -60px;
  top: 175px;
}
.locationGateFulEs {
  width: 250px;
  left: -75px;
  top: 175px;
}

.txtSave {
  margin-right: 15px;
  word-wrap: break-word;
  text-align: center;
  width: 225px;
}
.locationSaveVi {
  left: -75px;
  margin-top: 125px;
}
.locationSaveEn {
  left: -65px;
  margin-top: 100px;
}

.txtNews {
  font-size: 32px;
  font-weight: 500;
}
.locationNewsVi {
  left: -70px;
  bottom: 85px;
}
.locationNewsEn {
  left: -50px;
  bottom: 85px;
}

.locationNewsTh {
  left: -15px;
  bottom: 85px;
}

.locationSellVi {
  top: 275px;
  right: -75px;
}

.locationSellTh {
  right: -20px;
  top: 290px;
}
.locationSellEs {
  top: 275px;
  right: -30px;
}

.txtStaff {
  width: 230px;
  word-wrap: break-word;
  text-align: center;
}
.locationStaffVi {
  bottom: 200px;
  right: -75px;
}
.locationStaffTh {
  bottom: 120px;
  right: -60px
}
.locationStaffEn {
  bottom: 175px;
  right: -75px;
}
.locationStaffEs {
  bottom: 160px;
  right: -75px;
}
.txtBottom {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 24px;
}

.btn {
  display: flex;
  align-items: center;
  padding: 12px 45px 12px 25px;
  margin-bottom: 55px;
  border-radius: 5px;
  background-color: #6415FF;
  font-size: 32px;
  font-weight: 500;
  .txtButton {
    color: #fff;
  }
  .icon {
    margin-left: 15px;
  }
}
.btn:hover {
  background-color: #5011cc;
  .txtBottom {
    color: #f3f4f6
  }
}

.span {
  font-size: 32px;
  font-weight: 500;
}

.original-content {
  display: none;
}
.video {
  width: 305px;
  height: 675px;
  border-radius: 35px;
  overflow: "hidden";
  margin-left: 5px;
  top: 90px;
}

.layout {
  max-width: 1280px;
  margin-left: "auto";
  margin-right: "auto";
  position: "relative";
}

.spanNews {
  color: #b23a91;
}
.spanStaff {
  color: #7ad183;
}
.spanSell {
  color: #d87272;
}
.spanGift {
  color: #4f66a1;
}
.spanSave {
  color: #917ad1;
}

@media screen and (max-width: 1015px) {
  .hidden-content {
    display: none;
  }
  .original-content {
    display: block;
  }
  .btn {
    padding: 16px;
    font-size: 20px;
  }
  .txtBottom {
    margin-top: 16px;
    font-size: 15px;
    text-align: center;
  }
  .title {
    font-size: 32px;
  }
  .decor3 {
    left: -100px;
    top: 20px;
  }
  .video {
    height: 592px;
    border-radius: 60px;
    top: 25px;
    left: 5px;
    z-index: 1;
  }
  .line {
    right: 5px;
    bottom: -260px;
  }
  .layoutContent {
    flex: 1;
    flex-direction: "colum";
    margin-top: 56px;
    margin-left: 25px;
    z-index: 2;
  }
  .spacingIcon {
    margin-left: 12px;
    font-size: 20px;
  }
}
